.sticky {
	width: 100%;
	background: $white;
	box-shadow: 1px 1px 1px $black;
	-webkit-box-shadow: 1px 1px 1px $black;
	-moz-box-shadow: 1px 1px 1px $black;
	z-index: 1 !important;
}

@include breakpoint(large) {
	.vm {
		position:relative;
		top:50%;
		left:50%;
		-ms-transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
}

.v-space {
	padding: 50px 0;

	@include breakpoint(small only) {
		padding: 15px 0;
	}
}

.bkg-light {
	background: #f7f7f7;
	box-shadow: 0 0 18px 0px #ccc inset;
}

.bkg-dark {
	background: #00546b;
	box-shadow: 0 0 18px 0px #1f1f1f inset;
	color: $white;

	blockquote {
		color: $white;

		cite {
			color: #d2d2d2;
		}
	}
}

.divider {
	position: relative;
	height: 4px;
	width: 100%;

	.dot {
		margin: auto;
		border-radius: 50%;
		width: 15px;
		height: 15px;
		top: 0;
		position: absolute;
		background: #272727;
		bottom: 0;
		left: 0;
		right: 0;
	}

	&:before, &:after {
		content: "";
		float: left;
		height: 4px;
		width: 45%;
		background: #272727;
	}

	&:after {
		float: right;
	}
}

#gallery {
	border: 2px solid #232323;
	box-shadow: 0 0 5px #404040;
	border-radius: 2px;

	.ug-gallery-wrapper, .ug-slider-wrapper {
		background-color: #3d3d3d;
	}
}

.card {
	padding: 5%;

	img {
		width: 370px;
		height: 250px;
		margin: 0 auto;
	}

	.card-section {
		position: relative;
	}

	@include breakpoint(large) {
		padding: 0;

		&.large img {
			width: 568px;
			height: 300px;
		}
	}
}

button[type="button"] {
	cursor: pointer;
}

header {
	padding: 0.5rem;
	color: #ffffff;
	background: #3d3d3d;
}

footer {
	@extend header;
	padding: 0.7rem;
	border-top: 1px solid #292929;
}

.top-bar {
	.menu > li > a {
		font-size: 13px;
		color: #212121;
		text-transform: uppercase;

		&.button {
			padding: 0.5rem 1rem;
			border: 1px solid #949596;
		}
	}
}

.title-bar {
	.menu-icon {
		height: 20px;
	}
}

.banner {
	background-image: url("/assets/img/banner.jpg");
	background-size: 100% 100%;
	filter: grayscale(50%);
	-webkit-filter: grayscale(50%);
	padding: 30px 0;

	@include breakpoint(medium only) {
		padding: 60px 0;
	}

	@include breakpoint(large) {
		padding: 120px 0;
	}

	.row {
		height: 100%;

		.wrap {
			background-color: rgba(195, 195, 195, 0.7);
			padding: 20px;

			h1 {
				font-weight: bold;
				text-shadow: 0px 0px 5px #fefefe;
				color: #272727;
				margin: 0;
				padding: 20px 0;

				@include breakpoint(large) {
					padding: 45px 0;
					font-size: 60px;
				}
			}

			h4 {
				font-weight: bold;
				text-shadow: 0 0 2px $white;
				color: #272727;
				padding: 20px 0;

				@include breakpoint(large) {
					padding: 45px 0;
				}
			}
		}
	}

	&.small {
		padding: 35px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center 85%;

		@include breakpoint(small only) {
			padding: 10px;
		}

		.wrap {
			h1 {
				padding: 0;
			}
		}
	}
}
